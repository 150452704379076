@charset "utf-8";
// @import  "compass";

/*
  Copyright J-LINE CORPORATION. All rights reserved.
  http://www.j-line.co.jp/
*/

/* /////////////////////////////////////////////////////////////

	リセット基準テーマ

///////////////////////////////////////////////////////////// */


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    text-decoration: underline;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

//

/* /////////////////////////////////////////////////////////////

	ベーススタイル

///////////////////////////////////////////////////////////// */


/* ----------------------------------------------------------
	0. リセット & 追加要素の設定
------------------------------------------------------------- */
video, audio, canvas {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}
audio:not([controls]) { display: none; }
[hidden] { display: none; }

/* ----------------------------------------------------------
	1. ベース
------------------------------------------------------------- */
html {
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
body {
	background: #fff;
	color: #333;
	//font: 87.5%/1.6 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', 'Meiryo', 'MS UI Gothic', sans-serif;
	word-wrap: break-word;
}
/* ----------------------------------------------------------
	2. ブロック要素
------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6 { font-weight: bold; }
ul { list-style: none; }
ol { list-style: decimal inside; }

pre {
	font-family: monospace, serif;
	white-space: pre-wrap;
	word-wrap: break-word;
}

/* ----------------------------------------------------------
	3. インライン要素
------------------------------------------------------------- */
a:focus { outline: thin dotted; }
a:hover, a:active { outline: 0; }
img { vertical-align: middle; }

/* ----------------------------------------------------------
	4. テキスト
------------------------------------------------------------- */
address, cite, em, dfn, i, var { font-style: normal; }
strong, b { font-weight: bold; }
code, samp, kbd { font-family: monospace, sans-serif; }
abbr {
	border: 0;
	font-variant: normal;
}
abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}
sup { vertical-align: text-top; }
sub { vertical-align: text-bottom; }
br { *letter-spacing: 0; }
p{ line-height: 1.6; }

/* ----------------------------------------------------------
	5. テーブル
------------------------------------------------------------- */
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, td {
	font-weight: normal;
	text-align: left;
}

/* ----------------------------------------------------------
	6. フォーム部品
------------------------------------------------------------- */
input, textarea, select {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	*font-size: 100%;
	padding:0;
}
input, button {
	line-height: normal;
	vertical-align: middle;
}
textarea {
	overflow: auto;
	vertical-align: top;
}
select {
	background-color: inherit;
	line-height: normal;
}
label { cursor: pointer; }
legend {
	color: #333;
	*margin-left: -7px;
}


